import { lazy, useMemo } from 'react';
import AppThemeProvider from 'AppThemeProvider';
import { Icon, IconButton } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { navigateRef, notistackRef } from 'constants/RefConstants';
import Suspense from 'common/Suspense';
import LoadingModal from 'common/LoadingModal';
import useLoadingModal from 'hooks/useLoadingModal';
import './App.css';
import { getAppSubdomainBooleans } from 'utils/GlobalUtils';
import { useNavigate } from 'react-router-dom';

function App() {
  navigateRef.current = useNavigate();

  const { isLoadingModal } = useLoadingModal();

  const { isDefault, isRideDomain, isLogisticsDomain, isShopDomain } = useMemo(
    () => getAppSubdomainBooleans(),
    []
  );

  return (
    <AppThemeProvider>
      <SnackbarProvider
        ref={notistackRef}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        preventDuplicate
        action={(key) => (
          <IconButton
            onClick={() => {
              notistackRef.current.closeSnackbar(key);
            }}
            color='inherit'
            size='small'
          >
            <Icon>close</Icon>
          </IconButton>
        )}
      >
        <Suspense>
          {isDefault && <DefaultApp />}
          {isRideDomain && <RideApp />}
          {isLogisticsDomain && <LogisticsApp />}
          {isShopDomain && <ShopApp />}
        </Suspense>
      </SnackbarProvider>
      <LoadingModal open={isLoadingModal} />
    </AppThemeProvider>
  );
}

export default App;

const DefaultApp = lazy(() => import('apps/default/Default'));
const RideApp = lazy(() => import('apps/ride/Ride'));
const LogisticsApp = lazy(() => import('apps/logistics/Logistics'));
const ShopApp = lazy(() => import('apps/food/Food'));
